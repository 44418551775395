<template>
  <nav class="tap-bar" data-t="tap-bar">
    <NuxtI18nLink
      v-for="item in tapBarItems"
      :key="item.title"
      :to="item.link"
      active-class="active"
      class="tap-bar-item"
      :class="{ active: item.isActive, default: !item.isActive }"
      :data-t="`tap-bar-item-zx98-${item.iconName}`"
      prefetch
    >
      <div class="icon-wrapper">
        <StIcon v-if="item.iconName" :name="item.iconName" :size="24" />
        <StIcon
          v-else-if="item.avatar"
          class="avatar"
          name="user-solid"
          alt="avatar"
          height="24"
          width="24"
        />
        <StCounter
          v-if="item.counter"
          :label="item.counter"
          bg-color="orange"
          size="xxs"
          type="circle"
          class="counter"
        />
      </div>

      <span> {{ item.title }}</span>
    </NuxtI18nLink>
  </nav>
</template>

<script setup lang="ts">
import { useUserStore } from '@st/user/stores/useUserStore'
import { useActiveBetsStore } from '@st/coupon/stores/useActiveBetsStore'
import { useNotificationsStore } from '@st/profile/stores/useNotificationsStore'
import type { IconName } from '@st/ui/components/StIcon/types'
import { useBonusesCountStore } from '@st/bonuses/stores/useBonusesCountStore'

const { t } = useI18n()
const { isAuthenticated, user } = storeToRefs(useUserStore())
const { betCounter } = storeToRefs(useActiveBetsStore())
const { bonusesCount } = storeToRefs(useBonusesCountStore())
const { activeNotificationsCounter } = storeToRefs(useNotificationsStore())
const avatar = computed(() => user.value?.avatarUrl ?? '')

const { isCasinoPage, isSportPage, checkMatchLinkToRoute } =
  useMatchLinkToRoute()

const profileLink = computed(() =>
  isAuthenticated.value
    ? {
        avatar: avatar.value,
        title: t('tapBar.profile'),
        counter: activeNotificationsCounter.value + bonusesCount.value,
        link: '/mobile/personal/profile',
        isActive: checkMatchLinkToRoute('/mobile/personal/profile'),
      }
    : {
        iconName: 'login' as IconName,
        title: t('tapBar.login'),
        counter: 0,
        link: '?modal=login',
        isActive: false,
      },
)

const { isEnabledTournaments } = useEnabledTournaments()

const tapBarItems = computed(() => [
  {
    iconName: 'casino' as IconName,
    title: t('tapBar.casino'),
    counter: 0,
    link: '/mobile/casino',
    isActive: isCasinoPage.value,
  },
  {
    iconName: 'all-sports' as IconName,
    title: t('tapBar.sport'),
    counter: 0,
    link: '/mobile/sport',
    isActive: isSportPage.value,
  },
  ...(!isEnabledTournaments.value
    ? [
        {
          iconName: 'cybersport' as IconName,
          title: t('tapBar.cybersport'),
          counter: 0,
          link: '/mobile/cybersport',
          isActive: checkMatchLinkToRoute('/mobile/cybersport'),
        },
      ]
    : []),
  {
    iconName: 'bet' as IconName,
    title: t('tapBar.myBets'),
    counter: isAuthenticated.value ? betCounter.value : 0,
    link: '/mobile/personal/bets',
    isActive: checkMatchLinkToRoute('/mobile/personal/bets'),
  },
  ...(isEnabledTournaments.value
    ? [
        {
          iconName: 'cup-solid' as IconName,
          title: t('tapBar.tournaments'),
          counter: 0,
          link: '/mobile/tournaments',
          isActive: checkMatchLinkToRoute('/mobile/tournaments'),
        },
      ]
    : []),
  profileLink.value,
])
</script>

<style scoped>
.tap-bar {
  position: sticky;
  z-index: 100;
  right: 0;
  bottom: 0;
  left: 0;

  display: flex;
  align-items: flex-start;

  height: 60px;
  padding-bottom: var(--spacing-150);

  background: var(--background-primary);
  backdrop-filter: blur(12px);
  border-top: var(--border-width-light, 0.5px) solid var(--border-primary);
}

.tap-bar-item {
  display: flex;
  flex: 1 0 0;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  min-width: 0;
  padding: var(--spacing-075) 0;

  font: var(--mobile-caption-2-medium);
  color: var(--text-tertiary);
  text-decoration: none;

  &.active {
    color: var(--text-primary);
  }

  &.default {
    color: var(--text-tertiary);
  }
}

.icon-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.counter {
  position: absolute;
  top: -4px;
  right: -5px;
}

.avatar {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 24px;
  height: 24px;

  background-color: var(--text-tertiary);
  border-radius: var(--border-radius-full);
}
</style>
